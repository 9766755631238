import React, {useState, useContext, useEffect, useCallback} from 'react';
import {Table as AntTable, ConfigProvider, Row, Col, Image, Button, Radio} from 'antd';
import AppContext from "../../context/AppContext";


/**
 * table component
 */
const TableFuncComponent = ($props) => {

    /** @type {AdminBase} */
    const $app = useContext(AppContext);

    const [options, setOptions] = useState()
    const [state, setState] = useState({
        load: true,
        data: {
            labels: $app.record.getLabels(),
            response: {
                items: false, total: 0, page: {total: 0, size: 0, current: 0},
            }
        }
    });

    const loadData = useCallback(() => {
        if (!state.load)
            return;

        setState({
            load: false,
            data: $props.data
        });

        setOptions($props.options)

    }, [$app, state])


    useEffect(() => {
        loadData()
    }, [state, loadData])


    if (state.load === true) {
        return ''
    }

    const changePage = ($page) => {
        $app.route.to('', {
            page: $page
        })
        loadData()
    }

    /**
     * @param $response
     * @returns {{columns: [], source: []}}
     */
    const getSourceAndColumns = ($response) => {

        let $actions = false;
        let dataSource = [];
        if (!$response || !$response.items)
            return {};
        Object.entries($response.items).map((k, i) => {
            let $model = k[1];

            let $defaultActionsToCheck = ['view', 'update', 'delete'];
            if ($props.checkActions)
                $defaultActionsToCheck.push($props.checkActions)
            $actions = ($app.content.renderActionButtonsMini({
                    id: $model.id,
                    only: $defaultActionsToCheck
                }
            ));

            delete $model['text']
            delete $model['data']
            delete $model['history']
            let $img = $model['image'] ? $model['image'] : ($model['images'] ? $model['images'] : null)
            if ($img && typeof $img === 'object' && $img[0]) {
                let $imgData = $img[0]
                if ($imgData.url) {
                    $model['image'] = <Image
                        width={'auto'}
                        height={125}
                        src={$imgData.url}
                    />
                    $model['images'] = $model['image'];
                }
            }

            let $files = $model['file'] ? $model['file'] : ($model['files'] ? $model['files'] : null)

            if ($files && typeof $files === 'object') {
                if ($files.link) {
                    $model['file'] = <a href={$files.link} download={$files.name} target='_blank'>
                        {$files.name}
                    </a>
                }
                if ($files[0] && $files[0].link) {
                    let files = []
                    Object.keys($files).map((k, i) => {
                        files.push(
                            (
                                <div key={$files[k].uid}>
                                    <a href={$files[k].link}>
                                        {$files[k].name}
                                    </a>
                                    <br/>
                                </div>
                            )
                        )
                        return files
                    });
                    $model['files'] = files;
                }
            }

            if ($response.options) {
                Object.keys($model).map((k, v) => {
                    if ($response.options[k]) {
                        let $ov = $response.options[k];
                        let $vv = $model[k];
                        if ($ov[$vv] !== undefined)
                            $model[k] = $ov[$vv]
                    }
                })
            }


            if ($props.excludeFields) {
                Object.keys($model).map(($checkKey) => {
                    if ($props.excludeFields.includes($checkKey))
                        delete $model[$checkKey]
                    return '';
                })
            }

            if ($props.optionFields) {
                Object.keys($model).map(($checkKey) => {
                    if ($props.optionFields.includes($checkKey)) {

                        if ($model[$checkKey] !== undefined && options && options[$checkKey]) {
                            let $v = $model[$checkKey];
                            let $optSet = options[$checkKey];
                            if ($optSet[$v])
                                $model[$checkKey] = $optSet[$v]
                        }
                    }
                    return '';
                })
            }
            return dataSource.push(Object.assign($model, {
                key: $model.id,
                actions: $actions
            }));
        });

        let columns = [];
        if (!$props.data.labels)
            return {};

        delete $props.data.labels['data']
        delete $props.data.labels['text']
        delete $props.data.labels['history']
        if ($props.excludeFields) {
            Object.keys($props.data.labels).map(($checkKey) => {
                if ($props.excludeFields.includes($checkKey))
                    delete $props.data.labels[$checkKey]
                return '';
            })
        }

        Object.entries($props.data.labels).map((k) => {
            return columns.push({
                title: k[1], dataIndex: k[0], key: k[0], render: (d) => {
                    if (typeof d === 'object')
                        return d
                    return (
                        //render content value
                        <div style={{display: 'contents'}} dangerouslySetInnerHTML={{__html: d}}/>
                    )
                }
            })
        });

        if ($actions) {
            columns.push({title: 'Действия', dataIndex: 'actions', key: 'actions'});
        }

        return {
            source: dataSource,
            columns: columns,
        }
    }

    /**
     * @param $response
     * @returns {{total: number, defaultPageSize: number, size: (number|PaymentItem), onChange: Table.changePage, defaultCurrent: number}}
     */
    const getPageSettings = ($response) => {
        return {
            current: ($response.page.current) + 1,
            defaultCurrent: ($response.page.current) + 1,
            defaultPageSize: $response.page.size,
            size: 1, // max num of pagination buttons
            pageSize: $response.page.size,//items per page
            total: $response.total, /// total item count
            onChange: changePage
        };
    };


    let $response = $props.data.response;
    if (!$response || !$response.page)
        return '';

    let $sourceAndColumns = getSourceAndColumns($response);

    let $page = ($response.total <= $response.page.size) ? false :
        getPageSettings($response);

    return (
        <div>
            <ConfigProvider
                // renderEmpty={() => (!state.load && $response.total === 0 ? 'Нет данных' : 'Загрузка ...')}
            >
                <AntTable
                    bordered
                    scroll={{x: 800}}
                    size={$app.state.siteSize}
                    columns={$sourceAndColumns.columns}
                    dataSource={$sourceAndColumns.source}
                    pagination={$page}
                />
            </ConfigProvider>
        </div>
    );
}


class Table extends React.Component {

    static contextType = AppContext;

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            options: {}
        }
    }

    fetchData = () => {

        let $app = this.context;

        return $app.record.getList(($result) => {
            this.setState({data: $result})
            if ($result) {
                if ($result.response && $result.response.options)
                    this.setState({options: $result.response.options})
                let $tmpData = {};
                if ($app.state.table) {
                    let $d = $app.state.table.data;
                    $tmpData[$app.route.controller] = $result;
                    $tmpData = Object.assign($d, $tmpData)
                } else {
                    $tmpData[$app.route.controller] = $result;
                }
                $app.setState({
                    table: {
                        data: $tmpData,
                        name: $app.route.controller,
                        refresh: () => this.fetchData(),
                    }
                })
            }
        });
    }

    componentDidMount() {
        this.fetchData();
    }

    render() {
        return (
            <div>
                {/*{this.renderFilter()}*/}
                {this.renderTypeFilter()}
                {<TableFuncComponent {...this.props} data={this.state.data} options={this.state.options}/>}
            </div>
        )
    }

    /**
     * @return {JSX.Element}
     */
    renderTypeFilter = () => {
        /** @type {AdminBase} */
        let $app = this.context
        let controller = $app.route.controller;
        if (controller !== 'article')
            return <></>;
        if (!$app.state.table || !$app.state.table.data.article)
            return <></>;

        let types = Object.entries($app.state.table.data.article.options.type).map((e, k) => {
            return (
                <Radio.Button style={{height: 'auto', padding: "10px 20px"}} onClick={() => {
                    $app.route.to('/' + $app.route.controller, {
                        filter: {type: e[0]},
                    })
                }} key={k}>
                    {

                        <b style={{
                            color: ($app.route.getParam('filter.type') === e[0] ? 'green' : ''),
                            textTransform: 'uppercase',
                            fontSize: '0.9em'
                        }}>
                            {e[1]}
                        </b>

                    }
                </Radio.Button>
            )
        })
        return (
            <div>

                <Radio.Button style={{height: 'auto', padding: "10px 20px"}} onClick={() => {
                    $app.route.to('/' + $app.route.controller)
                }} key={9}>
                    <b style={{
                        color: ($app.route.getParam('filter.type', true) === true ? 'green' : ''),
                        textTransform: 'uppercase',
                        fontSize: '0.9em'
                    }}>
                        Все
                    </b>
                </Radio.Button>
                {types}

                <br/>
                <br/>
            </div>
        )
    }
    /**
     * @return {JSX.Element}
     */
    renderFilter = () => {
        /** @type {AdminBase} */
        let $app = this.context
        let $form = $app.HInput.new(this)

        let gridConf = {
            xs: {span: 24},
            sm: {span: 12},
            md: {span: 6},
            lg: {span: 3},
        }
        if (!this.state.data.labels)
            return false;


        let fields = Object.entries(this.state.data.labels).map((item, key) => {
            let $name = item[0];
            let $label = item[1];

            return (
                <Col key={$name} {...gridConf}>
                    {$form.text($name, {}, {
                        rules: false,
                        label: $label
                    })}
                </Col>
            )
        })

        let $items = (
            <div className={'content-block-gray'}>
                <Row gutter={20}>
                    {fields}
                </Row>
                {$form.submitButton({}, 'OK')}

            </div>
        )


        return (
            <div>
                {$form.render($items, {
                    onFinish: () => {
                        alert('DONE')
                    }
                })}
                <br/>
            </div>
        )
    }
}

export default Table;
